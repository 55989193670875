import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import UserNavbar from './UserNavbar';
import './SearchResults.css';
import config from '../functions/config';

const SearchResults = () => {
  const location = useLocation();
  const { users } = location.state || { users: [] };
  const [chatRequested, setChatRequested] = useState({});
  const [blockedUsers, setBlockedUsers] = useState(new Set());

  useEffect(() => {
    // Check local storage for previously requested chats
    const storedChatRequests = JSON.parse(localStorage.getItem('chatRequests')) || {};
    const storedBlockedUsers = new Set(JSON.parse(localStorage.getItem('blockedUsers')) || []);

    setChatRequested(storedChatRequests);
    setBlockedUsers(storedBlockedUsers);
  }, []);

  const requestChat = async (id) => {
    try {
      // const url = `http://localhost:8000/api/request_chat/${id}/`;
      const url = `${config.apiUrl}request_chat/${id}/`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ message: 'Requesting to chat' }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to request chat: ${errorText}`);
      }

      setChatRequested((prev) => {
        const updatedRequests = { ...prev, [id]: true };
        localStorage.setItem('chatRequests', JSON.stringify(updatedRequests)); // Save to local storage
        return updatedRequests;
      });
    } catch (err) {
      console.error(err.message);
    }
  };

  const blockUser = async (userId) => {
    try {
      // const url = `http://localhost:8000/api/block_user/${userId}/`;
      const url = `${config.apiUrl}block_user/${userId}/`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to block user: ${errorText}`);
      }

      setBlockedUsers((prev) => {
        const updatedBlocked = new Set(prev).add(userId);
        localStorage.setItem('blockedUsers', JSON.stringify([...updatedBlocked])); // Save to local storage
        return updatedBlocked;
      });
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div className="search-results-container">
      <UserNavbar />
      <h2>Search Results</h2>
      <div className="search-results-list">
        {users.length > 0 ? (
          users.map(user => (
            <div key={user.id} className="user-details-card">
              <div className="user-header">
                {/* <img src={`http://localhost:8000${user.profile_picture_1}`} alt="Profile" className="profile-picture" /> */}
                <img src={`${config.baseUrl}${user.profile_picture_1}`} alt="Profile" className="profile-picture" />
                <h3>{user.name}</h3>
              </div>
              <div className="user-details">
                <p>Email: {user.email}</p>
                <p>Gender: {user.gender}</p>
                <p>Age: {user.age}</p>
                <p>Height: {user.height}</p>
                <p>Education: {user.education}</p>
                <p>Job: {user.job}</p>
                <p>Place: {user.place}</p>
                <p>District: {user.district}</p>
                <p>Mobile: {user.mobile}</p>
                <p>Religion: {user.religion}</p>
                <p>Caste: {user.caste}</p>
                <p>Marriage Level: {user.marriage_level}</p>
                <p>Mother's Name: {user.mother_name}</p>
                <p>Mother's Job: {user.mother_job}</p>
                <p>Father's Name: {user.father_name}</p>
                <p>Father's Job: {user.father_job}</p>

                {/* Sibling Details */}
                <div className="sibling-details">
                  <h4>Sibling Details</h4>
                  {[1, 2, 3].map(index => {
                    const siblingName = user[`sibling_${index}_name`];
                    const siblingMarried = user[`sibling_${index}_married`];
                    return (
                      siblingName || siblingMarried ? (
                        <div key={index} className="sibling">
                          <p>Sibling Name: {siblingName || 'N/A'}</p>
                          <p>Marital Status: {siblingMarried || 'N/A'}</p>
                        </div>
                      ) : null
                    );
                  })}
                  {(!user.sibling_1_name && !user.sibling_2_name && !user.sibling_3_name) && (
                    // <p>No siblings available.</p>
                    <>
                    <p>No siblings data available.</p>
                    <br />
                  </>
                  )}
                </div>

                {/* Profile Pictures */}
                {/* <div className="profile-pictures">
                  <h4>Profile Pictures</h4>
                  {Array.from({ length: 5 }, (_, index) => user[`profile_picture_${index + 1}`]).map((pic, idx) => (
                    pic ? <img key={idx} src={`http://localhost:8000${pic}`} alt={`Profile ${idx + 1}`} /> : null
                  ))}
                </div> */}
                 <div className="profile-pictures">
                  <h4>Profile Pictures</h4>
                  {Array.from({ length: 5 }, (_, index) => user[`profile_picture_${index + 1}`]).map((pic, idx) => (
                    pic ? <img key={idx} src={`${config.baseUrl}${pic}`} alt={`Profile ${idx + 1}`} /> : null
                  ))}
                </div>

                {/* Chat Request Button */}
                {!chatRequested[user.user] ? (
                  <button onClick={() => requestChat(user.user)} className="request-chat-button">
                    Request Chat
                  </button>
                ) : (
                  <p>Chat request sent!</p>
                )}

                {/* Block User Button */}
                {!blockedUsers.has(user.user) ? (
                  <button onClick={() => blockUser(user.user)} className="block-user-button">
                    Block User
                  </button>
                ) : (
                  <p>User Blocked</p>
                )}
              </div>
            </div>
          ))
        ) : (
          <p>No results found.</p>
        )}
      </div>
    </div>
  );
};

export default SearchResults;
