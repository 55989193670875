import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UserNavbar from './UserNavbar';
import './PaymentPage2.css';
import config from '../functions/config';

const packages = [
    // { id: 1, name: 'Trial Package', amount: 0, duration: 10 }, // 10 days
    { id: 2, name: 'Basic Package', amount: 50, duration: 1 },   
    { id: 3, name: 'Standard Package', amount: 100, duration: 3 },
    { id: 4, name: 'Premium Package', amount: 150, duration: 6 },
];

const PaymentPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userId } = location.state || {};
    const [paymentData, setPaymentData] = useState({
        cardNumber: '',
        cardHolderName: '',
        expiryDate: '',
        cvv: '',
        selectedPackage: packages[0].id,
        startDate: '',
    });
    const [errors, setErrors] = useState({});
    const [endDate, setEndDate] = useState('');
    const [step, setStep] = useState(1);

    useEffect(() => {
        const pkg = packages.find(pkg => pkg.id === paymentData.selectedPackage);
        if (pkg && paymentData.startDate) {
            const start = new Date(paymentData.startDate);
            const end = new Date(start);
            if (pkg.id === 1) {
                end.setDate(start.getDate() + 10);
            } else {
                end.setMonth(start.getMonth() + pkg.duration);
            }
            setEndDate(end.toISOString().split('T')[0]);
        }
    }, [paymentData.selectedPackage, paymentData.startDate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPaymentData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handlePackageChange = (e) => {
        setPaymentData((prev) => ({
            ...prev,
            selectedPackage: parseInt(e.target.value, 10)
        }));
    };

    const validatePackageAndDate = () => {
        const newErrors = {};
        if (!paymentData.startDate) {
            newErrors.startDate = "Start date is required.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validatePayment = () => {
        const newErrors = {};
        if (paymentData.selectedPackage !== 1) { // Only validate if not the trial package
            if (!paymentData.cardNumber.match(/^\d{16}$/)) {
                newErrors.cardNumber = "Card number must be 16 digits.";
            }
            if (!paymentData.cardHolderName) {
                newErrors.cardHolderName = "Card holder's name is required.";
            }
            if (!paymentData.expiryDate.match(/^\d{2}\/\d{2}$/)) {
                newErrors.expiryDate = "Expiry date must be in MM/YY format.";
            }
            if (!paymentData.cvv.match(/^\d{3}$/)) {
                newErrors.cvv = "CVV must be 3 digits.";
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("User ID:", userId);
        if (step === 1) {
            if (!validatePackageAndDate()) {
                return;
            }
            setStep(2);
        } else if (step === 2) {
            if (!validatePayment()) {
                return;
            }

            const pkg = packages.find(pkg => pkg.id === paymentData.selectedPackage);

            const formDataToSend = new FormData();
            formDataToSend.append('userId', userId);
            if (paymentData.selectedPackage !== 1) { // Only add card details if not the trial package
                formDataToSend.append('cardNumber', paymentData.cardNumber);
                formDataToSend.append('cardHolderName', paymentData.cardHolderName);
                formDataToSend.append('expiryDate', paymentData.expiryDate);
                formDataToSend.append('cvv', paymentData.cvv);
            }
            formDataToSend.append('packageId', paymentData.selectedPackage);
            formDataToSend.append('startDate', paymentData.startDate);
            formDataToSend.append('endDate', endDate);
            formDataToSend.append('amount', pkg.amount);

            try {
                // const response = await fetch('http://localhost:8000/renew-payment/', {
                    const response = await fetch(`${config.baseUrl}/renew-payment/`, {
                    method: 'POST',
                    body: formDataToSend,
                });

                if (response.ok) {
                    const result = await response.json();
                    alert(result.message || 'Payment successful');
                    navigate('/');
                } else {
                    const errorResult = await response.json();
                    setErrors({ form: errorResult.error || 'Payment failed' });
                }
            } catch (error) {
                setErrors({ form: 'Network error. Please try again.' });
            }
        }
    };

    const selectedPackage = packages.find(pkg => pkg.id === paymentData.selectedPackage);

    return (
        <div className="payment-page-containers">
            <UserNavbar />
            <h2>Payment Page</h2>
            {errors.form && <div className="error-message">{errors.form}</div>}
            <form onSubmit={handleSubmit}>
                {step === 1 && (
                    <>
                        <div className="form-group">
                            <label htmlFor="package">Select Package</label>
                            <select
                                id="package"
                                name="package"
                                value={paymentData.selectedPackage}
                                onChange={handlePackageChange}
                                required
                            >
                                {packages.map(pkg => (
                                    <option key={pkg.id} value={pkg.id}>
                                        {pkg.name} - ${pkg.amount}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="startDate">Start Date</label>
                            <input
                                type="date"
                                id="startDate"
                                name="startDate"
                                value={paymentData.startDate}
                                onChange={handleChange}
                                required
                            />
                            {errors.startDate && <div className="error-message">{errors.startDate}</div>}
                        </div>

                        <div className="form-group">
                            <label>Total Amount: ${selectedPackage.amount}</label>
                        </div>

                        <div className="form-group">
                            <label>End Date: {endDate}</label>
                        </div>

                        <button type="submit">Next</button>
                    </>
                )}

                {step === 2 && (
                    <>
                        {paymentData.selectedPackage !== 1 && ( // Only show payment fields if not the trial package
                            <>
                                <div className="form-group">
                                    <label htmlFor="cardNumber">Card Number</label>
                                    <input
                                        type="text"
                                        id="cardNumber"
                                        name="cardNumber"
                                        value={paymentData.cardNumber}
                                        onChange={handleChange}
                                        placeholder="Card Number"
                                        maxLength="16"
                                        required
                                    />
                                    {errors.cardNumber && <div className="error-message">{errors.cardNumber}</div>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="cardHolderName">Card Holder's Name</label>
                                    <input
                                        type="text"
                                        id="cardHolderName"
                                        name="cardHolderName"
                                        value={paymentData.cardHolderName}
                                        onChange={handleChange}
                                        placeholder="Card Holder's Name"
                                        required
                                    />
                                    {errors.cardHolderName && <div className="error-message">{errors.cardHolderName}</div>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="expiryDate">Expiry Date (MM/YY)</label>
                                    <input
                                        type="text"
                                        id="expiryDate"
                                        name="expiryDate"
                                        value={paymentData.expiryDate}
                                        onChange={handleChange}
                                        placeholder="MM/YY"
                                        maxLength="5"
                                        required
                                    />
                                    {errors.expiryDate && <div className="error-message">{errors.expiryDate}</div>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="cvv">CVV</label>
                                    <input
                                        type="text"
                                        id="cvv"
                                        name="cvv"
                                        value={paymentData.cvv}
                                        onChange={handleChange}
                                        placeholder="CVV"
                                        maxLength="3"
                                        required
                                    />
                                    {errors.cvv && <div className="error-message">{errors.cvv}</div>}
                                </div>
                            </>
                        )}
                        <button type="submit">Submit Payment</button>
                    </>
                )}
            </form>
        </div>
    );
};

export default PaymentPage;
