import React, { useState, useEffect } from 'react';
// import VerticalNavbarAdmin from './VerticalNavbarAdmin';
// import HorizontalNavbarAdmin from './HorizontalNavbarAdmin';
import CombinedNavbar from './CombinedNavbar';
import './UnapprovedUsers.css'; 
import config from '../functions/config';

const UnapprovedUsers = () => {
  const [unapprovedUsers, setUnapprovedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUnapprovedUsers = async () => {
      try {
        // const response = await fetch('http://localhost:8000/api/unapproved-users/');
        const response = await fetch(`${config.apiUrl}unapproved-users/`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setUnapprovedUsers(data);
      } catch (error) {
        console.error('Error fetching unapproved users:', error);
        setError('Failed to load unapproved users.');
      } finally {
        setLoading(false);
      }
    };

    fetchUnapprovedUsers();
  }, []);

  const handleApprove = async (userId) => {
    try {
      // const response = await fetch(`http://localhost:8000/api/approve-user/${userId}/`, {
        const response = await fetch(`${config.apiUrl}approve-user/${userId}/`, {
        method: 'POST',
      });
  
      // Check if response is okay
      if (!response.ok) {
        // Read the response body as text for debugging
        const responseText = await response.text();
  
        // Try to parse JSON if applicable
        try {
          const errorData = JSON.parse(responseText);
          throw new Error(errorData.message || 'Network response was not ok');
        } catch {
          throw new Error(`Unexpected response: ${responseText}`);
        }
      }
  
      const data = await response.json();
      if (data.status === 'success') {
        alert(`User approved. Check your email`);
        setUnapprovedUsers(unapprovedUsers.filter(user => user.id !== userId));
      } else {
        alert(`Error: ${data.message}`);
      }
    } catch (error) {
      console.error('Error approving user:', error);
      alert(`Approval failed: ${error.message}`);
    }
  };
  const handleDisapprove = async (userId) => {
    try {
      // const response = await fetch(`http://localhost:8000/api/disapprove-user/${userId}/`, {
        const response = await fetch(`${config.apiUrl}disapprove-user/${userId}/`, {
        method: 'POST',
      });
  
      if (!response.ok) {
        const responseText = await response.text();
        console.error('Error response:', responseText);
        throw new Error('Network response was not ok');
      }
  
      // Remove the disapproved user from the list
      setUnapprovedUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
      alert('User disapproved successfully');
    } catch (error) {
      console.error('Error disapproving user:', error);
      alert(`Disapproval failed: ${error.message}`);
    }
  };
  

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="app">
      {/* <HorizontalNavbarAdmin /> */}
      <CombinedNavbar/>
      <div className="main-content">
        {/* <VerticalNavbarAdmin /> */}
        <div className="content-area">
          <h1>Unapproved Users</h1>
          {unapprovedUsers.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Email</th>
                  <th>Name</th>
                  <th>Gender</th>
                  <th>Education</th>
                  <th>Job</th>
                  <th>District</th>
                  <th>Mobile</th>
                  <th>Religion</th>
                  <th>Caste</th>
                  <th>Marriage Level</th>
                  <th>Payment Status</th>
                  <th>Package ID</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Amount</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {unapprovedUsers.map(user => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.email}</td>
                    <td>{user.name}</td>
                    <td>{user.gender}</td>
                    <td>{user.education}</td>
                    <td>{user.job}</td>
                    <td>{user.district}</td>
                    <td>{user.mobile}</td>
                    <td>{user.religion}</td>
                    <td>{user.caste}</td>
                    <td>{user.marriage_level}</td>
                    <td>{user.status}</td>
                    <td>{user.payment_details?.package_id}</td>
                    <td>{user.payment_details?.start_date}</td>
                    <td>{user.payment_details?.end_date}</td>
                    <td>{user.payment_details?.amount}</td>
                    {/* <td>
                      <button className="button-approve" onClick={() => handleApprove(user.id)}>Approve</button>
                      <button className="button-disapprove" onClick={() => handleDisapprove(user.id)}>Disapprove</button>
                    </td> */}
                    <td>
                      <div className="button-container">
                        <button className="button-approve" onClick={() => handleApprove(user.id)}>Approve</button>
                        <button className="button-disapprove" onClick={() => handleDisapprove(user.id)}>Disapprove</button>
                      </div>
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No unapproved users.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnapprovedUsers;
