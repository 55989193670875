import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UserNavbar from './UserNavbar';
import './ProfileViewDetails.css'; 
import config from '../functions/config';

const ProfileViewDetails = () => {
  const { username } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  // const baseUrl = 'http://localhost:8000'; // Change to your API's base URL

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem('token');

      try {
        // const response = await fetch(`http://localhost:8000/api/user_details/${username}/`, {
          const response = await fetch(`${config.apiUrl}user_details/${username}/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();
        setUserDetails(data);
      } catch (error) {
        console.error("Failed to fetch user details:", error.message);
      }
    };

    fetchUserDetails();
  }, [username]);

  return (
    <div>
      <UserNavbar />
      <div className="profile-details-container"> {/* Apply the CSS class here */}
        {userDetails ? (
          <>
            <h1>User Details for {userDetails.username}</h1>
            {userDetails.profile_picture && (
              // <img src={`${baseUrl}${userDetails.profile_picture}`} alt={`${userDetails.username}'s profile`} />
              <img src={`${config.baseUrl}${userDetails.profile_picture}`} alt={`${userDetails.username}'s profile`} />
            )}
            <p>Name: {userDetails.name}</p>
            <p>Gender: {userDetails.gender}</p>
            <p>Age: {userDetails.age}</p>
            <p>Height: {userDetails.height}</p>
            <p>Education: {userDetails.education}</p>
            <p>Job: {userDetails.job}</p>
            <p>Email: {userDetails.email}</p>
            <p>Mobile: {userDetails.mobile}</p>
            <p>Religion: {userDetails.religion}</p>
            <p>Caste: {userDetails.caste}</p>
            <p>Marriage Level: {userDetails.marriage_level}</p>
            <p>Mother's Name: {userDetails.mother_name}</p>
            <p>Father's Name: {userDetails.father_name}</p>
          </>
        ) : (
          <p>Loading user details...</p>
        )}
      </div><br></br>
    </div>
  );
};

export default ProfileViewDetails;
