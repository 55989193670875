import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import AdminPage from './components/AdminPage';
import UserPage from './components/UserPage';
import RegistrationForm from './components/RegistrationForm';
import PaymentPage from './components/PaymentPage ';
import ViewUsers from './components/ViewUsers';
import UnapprovedUsers from './components/UnapprovedUsers';
import ApprovedUsers from './components/ApprovedUsers';
import ProfilePage from './components/ProfilePage';
import EditProfile from './components/EditProfile';
import ResetPassword from './components/ResetPassword';
import UserDetails from './components/UserDetails';
import SearchResults from './components/SearchResults';
import PackageDetails from './components/PackageDetails';
import AdminUserPackageDetails from './components/AdminUserPackageDetails.jsx';
import PaymentPage2 from './components/PaymentPage2 .jsx';
import ProfileViewers from './components/ProfileViewers .jsx';
import ProfileViewDetails from './components/ProfileViewDetails.jsx';
import LoginForm from './components/LoginForm.jsx';

 

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/user" element={<UserPage />} />
        <Route path="/register" element={<RegistrationForm />} />
        <Route path="/login" element={<LoginForm />} />
        
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/paymentrenew" element={<PaymentPage2 />} />
        <Route path="/view-users" element={<ViewUsers />} />
        <Route path="/unapproved-users" element={<UnapprovedUsers />} />
        <Route path="/approved-users" element={<ApprovedUsers />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/editprofile" element={<EditProfile />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/user_profile_detail/:id" element={<UserDetails />} />
        <Route path="/search_results" element={<SearchResults />} />
        <Route path="/package-details" element={<PackageDetails />} />
        <Route path="/userpackagesadmin" element={<AdminUserPackageDetails />} />
        <Route path="/profile_viewers" element={<ProfileViewers />} />
        <Route path="/user/:username" element={<ProfileViewDetails />} />
        
        

        
      </Routes>
    </Router>
  );
}

export default App;
