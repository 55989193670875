import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserNavbar from './UserNavbar';
import './ProfileViewers.css'; 
import config from '../functions/config';

const ProfileViewers = () => {
  const [viewers, setViewers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchViewers = async () => {
      const token = localStorage.getItem('token');
  
      try {
        // const response = await fetch('http://localhost:8000/api/profile_viewers/', {
          const response = await fetch(`${config.apiUrl}profile_viewers/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
  
        const data = await response.json();
        setViewers(data);
      } catch (error) {
        console.error("Failed to fetch viewers:", error.message);
      }
    };
  
    fetchViewers();
  }, []);

  // const baseUrl = 'http://localhost:8000'; // Change this to your API's base URL

  const handleViewDetails = (username) => {
    navigate(`/user/${username}`); 
  };

  return (
    <div className="profile-viewers-container">
      <UserNavbar />
      <h2 className="profile-viewers-title">Profile Viewers</h2>
      {viewers.length === 0 ? (
        <p className="no-viewers-message">No one has viewed your profile yet.</p>
      ) : (
        <ul className="profile-viewers-list">
          {viewers.map(viewer => (
            <li key={viewer.username} className="profile-viewers-item">
              <div className="viewer-details">
                {viewer.user_profile_details.profile_picture && (
                  // <img
                  //   src={`${baseUrl}${viewer.user_profile_details.profile_picture}`}
                  //   alt={`${viewer.username}'s profile`}
                  //   className="viewer-image"
                  // />
                  <img
                    src={`${config.baseUrl}${viewer.user_profile_details.profile_picture}`}
                    alt={`${viewer.username}'s profile`}
                    className="viewer-image"
                  />
                )}
                <div className="viewer-info">
                  <span className="viewer-username">{viewer.username}</span>
                  <span className="viewer-timestamp">
                    viewed your profile on {new Date(viewer.viewed_at).toLocaleString()}
                  </span>
                </div>
                <button
                  className="view-details-button"
                  onClick={() => handleViewDetails(viewer.username)}
                >
                  View Details
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ProfileViewers;
