// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios'; // Import axios
// import './LoginForm.css';

// const LoginForm = ({ onClose }) => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     console.log('Submitting login request...');
//     try {
//       const response = await axios.post('http://localhost:8000/login/', {
//         username,
//         password
//       });
//       console.log('Response received:', response.data);
  
//       if (response.data.userType === 'admin') {
//         navigate('/admin');
//       } else if (response.data.userType === 'user') {
//         navigate('/user');
//       } else {
//         alert('Login failed: unexpected user type');
//       }
//     } catch (error) {
//       console.error('Error during login:', error);
//       if (error.response) {
//         // Server responded with a status other than 2xx
//         alert('Login failed: ' + (error.response.data.error || 'Unknown error'));
//       } else if (error.request) {
//         // No response received
//         alert('Login failed: No response from server');
//       } else {
//         // Other errors
//         alert('Login failed: ' + error.message);
//       }
//     }
//     onClose();
//   };
  

//   return (
//     <div className="login-form-overlay">
//       <div className="login-form-container">
//         <h2>Login</h2>
//         <form onSubmit={handleSubmit}>
//           <label htmlFor="username">Username:</label>
//           <input
//             type="text"
//             id="username"
//             value={username}
//             onChange={(e) => setUsername(e.target.value)}
//             required
//           />
//           <label htmlFor="password">Password:</label>
//           <input
//             type="password"
//             id="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             required
//           />
//           <button type="submit">Login</button>
//           <button type="button" onClick={onClose}>Close</button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default LoginForm;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios
import './LoginForm.css';
import config from '../functions/config';

const LoginForm = ({ onClose }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting login request...');
    console.log('Username:', username);
    console.log('Password:', password);

    try {
      // const response = await axios.post('http://localhost:8000/login/', {
        const response = await axios.post(`${config.baseUrl}/login/`, {
        username,
        password
      });
      console.log('Response received:', response.data);
  
      // Check if the token is present in the response
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);  // Save the token
        console.log('Token saved:', localStorage.getItem('token'));
        
  
        // Handle navigation based on userType
        if (response.data.userType === 'admin') {
          navigate('/admin');
        } else if (response.data.userType === 'user') {
          navigate('/user');
        } else {
          alert('Login failed: unexpected user type');
        }
      } else {
        alert('Login failed: no token received');
      }
    } catch (error) {
      console.error('Error during login:', error);
      if (error.response) {
        console.log('Full error response:', error.response);
        alert('Login failed: ' + (error.response.data.error || 'Unknown error'));
      } else if (error.request) {
        alert('Login failed: No response from server');
      } else {
        alert('Login failed: ' + error.message);
      }
    }
    onClose();
  };
  
  return (
    <div className="login-form-overlay">
      <div className="login-form-container">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Login</button>
          <button type="button" onClick={onClose}>Close</button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
