import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UserNavbar from './UserNavbar'; 
import './ProfilePage.css'; 
import config from '../functions/config';



const ProfilePage = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError('User not authenticated');
          setLoading(false);
          return;
        }
        
        // const response = await axios.get('http://localhost:8000/api/user_profile/', {
          const response = await axios.get(`${config.apiUrl}user_profile/`, {
          headers: { 'Authorization': `Token ${token}` },
        });

        setUserDetails(response.data);
      } catch (error) {
        setError('Error fetching user details');
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);
  
  if (loading) return <div>Loading profile...</div>;
  if (error) return <div>{error}</div>;
  if (!userDetails) return <div>No profile data</div>;

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="page-container">
      <UserNavbar />
      <main className="content">
        <div className="profile-card">
          <div className="profile-header">
            {/* <img src={`http://localhost:8000${userDetails.profile_picture_1}`} alt="Profile" className="profile-picture" /> */}
            <img src={`${config.baseUrl}${userDetails.profile_picture_1}`} alt="Profile" className="profile-picture" />
            <h2>Welcome, {userDetails.name}</h2>
          </div>
          <div className="profile-details">
            <p>Email: {userDetails.email}</p>
            <p>Gender: {userDetails.gender}</p>
            <p>Age: {userDetails.age}</p>
            <p>Height: {userDetails.height}</p>
            <p>Education: {userDetails.education}</p>
            <p>Job: {userDetails.job}</p>
            <p>Place: {userDetails.place}</p>
            <p>District: {userDetails.district}</p>
            <p>Mobile: {userDetails.mobile}</p>
            <p>Religion: {userDetails.religion}</p>
            <p>Caste: {userDetails.caste}</p>
            <p>Marriage Level: {userDetails.marriage_level}</p>
            <p>Mother's Name: {userDetails.mother_name}</p>
            <p>Mother's Job: {userDetails.mother_job}</p>
            <p>Father's Name: {userDetails.father_name}</p>
            <p>Father's Job: {userDetails.father_job}</p>

            <div className="sibling-details">
              <h3>Sibling Details</h3>
              {[1, 2, 3].map(index => {
                const siblingName = userDetails[`sibling_${index}_name`];
                const siblingMarried = userDetails[`sibling_${index}_married`];
                return (
                  siblingName || siblingMarried ? (
                    <div key={index} className="sibling">
                      <p>Sibling Name: {siblingName || 'N/A'}</p>
                      <p>Marital Status: {siblingMarried || 'N/A'}</p>
                    </div>
                  ) : null
                );
              })}
              {(!userDetails.sibling_1_name && !userDetails.sibling_2_name && !userDetails.sibling_3_name) && (
                <p>No siblings available.</p>
              )}
            </div>

            {/* <div className="profile-pictures">
              <h3>Profile Pictures</h3>
              {Array.from({ length: 5 }, (_, index) => userDetails[`profile_picture_${index + 1}`]).map((pic, idx) => (
                pic ? <img key={idx} src={`http://localhost:8000${pic}`} alt={`Profile ${idx + 1}`} /> : null
              ))}
            </div> */}
            <div className="profile-pictures">
              <h3>Profile Pictures</h3>
              {Array.from({ length: 5 }, (_, index) => userDetails[`profile_picture_${index + 1}`]).map((pic, idx) => (
                pic ? <img key={idx} src={`${config.baseUrl}${pic}`} alt={`Profile ${idx + 1}`} /> : null
              ))}
            </div>
          </div>
          <button className="print-button" onClick={handlePrint}>Print Profile</button>
        </div>
      </main>
    </div>
  );
};

export default ProfilePage;
