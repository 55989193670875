import React from 'react';
import Navbar from './Navbar';
import './Home.css';
// import { Link } from 'react-router-dom';


// Import images if they are in the src directory
import profileImage1 from './assets/images/h1.jpg';
import profileImage2 from './assets/images/h2.jpg';
import profileImage3 from './assets/images/h3.jpg';
import profileImage4 from './assets/images/h4.jpg';
import profileImage5 from './assets/images/h5.jpeg';
import profileImage6 from './assets/images/h6.jpg';
import featureImage from './assets/images/home1.jpg'; 
import homeImage from './assets/images/home4.webp';


const Home = () => {
  const highlightedProfiles = [
    // Bride profiles
    {
      age: 21,
      height: "5'3\"",
      language: "Malayalam",
      community: "Nair",
      education: "M.Sc.",
      profession: "Teaching / Academician",
      location: "Bharani, Alappuzha, Kerala",
      lastLogin: "3 months ago",
      image: profileImage1,
    },
    {
      age: 22,
      height: "5'0\"",
      language: "Malayalam",
      community: "Nair",
      education: "MCom",
      profession: "Not working",
      location: "Pooradam, Kozhikode, Kerala",
      lastLogin: "3 months ago",
      image: profileImage3,
    },
    {
      age: 27,
      height: "5'0\"",
      language: "Malayalam",
      community: "Ezhava",
      education: "MD / MS (Medical)",
      profession: "Not Working",
      location: "Kochi, Kerala",
      lastLogin: "3 months ago",
      image: profileImage2,
    },
    // Groom profiles
    {
      age: 25,
      height: "5'8\"",
      language: "Malayalam",
      community: "Nair",
      education: "MBA",
      profession: "Software Engineer",
      location: "Kochi, Kerala",
      lastLogin: "2 months ago",
      image: profileImage4,
    },
    {
      age: 30,
      height: "5'10\"",
      language: "Malayalam",
      community: "Ezhava",
      education: "B.Tech",
      profession: "Businessman",
      location: "Thrissur, Kerala",
      lastLogin: "1 month ago",
      image: profileImage5,
    },
    {
      age: 31,
      height: "5'10\"",
      language: "Malayalam",
      community: "Ezhava",
      education: "B.Tech",
      profession: "Businessman",
      location: "Kannur, Kerala",
      lastLogin: "1 month ago",
      image: profileImage6,
    },
  ];

  return (
    <div className="home">
      <Navbar />
      <header className="home-header">
        <h1>Welcome to Matrimony</h1>
        <p>Your journey to finding the perfect match starts here.</p>
      </header>
      <main className="home-main">
      <section id="about" className="home-introduction">
        <div className="introduction-content">
          <h2>What Makes Us Special</h2>
          <p>Our technology and people ensure complete support to help you find your partner.</p>
          <ul>
            <li><strong>Sign In:</strong> Register for free & put up your Profile</li>
            <li><strong>Connect:</strong> Select & connect with matches you like</li>
            <li><strong>Secured:</strong> Your data is safe and secure with us</li>
            <li><strong>Love:</strong> Find your special someone</li>
          </ul>
        </div>
        <div className="introduction-image">
          <img src={homeImage} alt="Introduction" />
        </div>
      </section>


        <img src={featureImage} alt="Features" className="feature-image" />
        <section className="home-features">
          <h2>Our Features</h2>
          <ul>
            <li><strong>Detailed Profiles:</strong> View comprehensive profiles that include personal details, interests, and photos.</li>
            <li><strong>Advanced Search Filters:</strong> Easily find matches by filtering based on age, location, community, and more.</li>
            <li><strong>Secure Messaging:</strong> Communicate safely with potential matches using our secure messaging system.</li>
            <li><strong>Compatibility Matching:</strong> Get suggested matches based on compatibility scores derived from your profile preferences.</li>
            <li><strong>Success Stories:</strong> Read inspiring success stories from members who found their perfect match.</li>
            <li><strong>Mobile App:</strong> Access all features on the go with our user-friendly mobile application.</li>
          </ul>
        </section>

        <section className="home-highlighted-profiles">
          <h2>Highlighted Profiles</h2>
          <p>Explore potential matches and find your perfect partner.</p>
          
          {/* Brides Section */}
          <h4>Brides</h4>
          <div className="profile-list">
            {highlightedProfiles.slice(0, 3).map((profile, index) => (
              <div key={index} className="profile-card">
                {profile.image ? (
                  <img src={profile.image} alt="Profile" className="profile-image" />
                ) : (
                  <p>Image not available</p>
                )}
                <p><strong>Age:</strong> {profile.age} yrs</p>
                <p><strong>Height:</strong> {profile.height}</p>
                <p><strong>Language:</strong> {profile.language}</p>
                <p><strong>Community:</strong> {profile.community}</p>
                <p><strong>Education:</strong> {profile.education}</p>
                <p><strong>Profession:</strong> {profile.profession}</p>
                <p><strong>Location:</strong> {profile.location}</p>
                <p><strong>Last Login:</strong> {profile.lastLogin}</p>
                <button>View Full Profile</button>
              </div>
            ))}
          </div>

          {/* Grooms Section */}
          <h4>Grooms</h4>
          <div className="profile-list">
            {highlightedProfiles.slice(3).map((profile, index) => (
              <div key={index + 3} className="profile-card">
                {profile.image ? (
                  <img src={profile.image} alt="Profile" className="profile-image" />
                ) : (
                  <p>Image not available</p>
                )}
                <p><strong>Age:</strong> {profile.age} yrs</p>
                <p><strong>Height:</strong> {profile.height}</p>
                <p><strong>Language:</strong> {profile.language}</p>
                <p><strong>Community:</strong> {profile.community}</p>
                <p><strong>Education:</strong> {profile.education}</p>
                <p><strong>Profession:</strong> {profile.profession}</p>
                <p><strong>Location:</strong> {profile.location}</p>
                <p><strong>Last Login:</strong> {profile.lastLogin}</p>
                <button>View Full Profile</button>
              </div>
            ))}
          </div>
        </section>
        {/* <section className="home-cta">
          <h2>Get Started</h2>
        <Link to="/register">
          <button>Sign Up</button>
        </Link>

        </section> */}
      </main>
      <footer className="home-footer">
        <div className="footer-row">
          <div className="footer-section">
            <h3>Support Request</h3>
            <p>Chat for Assistance</p>
            <p>Call Us: 7034 77 66 67</p>
            <p>Available On: Apple</p>
            <p>Follow Us:</p>
          </div>
          <div className="footer-section">
            <h3>Quick Links</h3>
            <ul>
              <li>Login</li>
              <li>Register</li>
              <li>Packages</li>
              <li>Success Story</li>
              <li>Featured Profiles</li>
              <li>About</li>
              <li>Blog</li>
              <li>Campaign</li>
              <li>Sitemap</li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Search Your Partner</h3>
            <ul>
              <li>Basic Search</li>
              <li>Quick Search</li>
              <li>WebID Search</li>
              <li>Advanced Search</li>
              <li>Religion Matrimony</li>
              <li>Hindu Matrimony</li>
              <li>Christian Matrimony</li>
              <li>Muslim Matrimony</li>
              <li>InterCaste Matrimony</li>
              <li>Divorcee Matrimony</li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Matrimony. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
