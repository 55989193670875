import React, { useState, useEffect } from 'react';
import './ViewUsers.css'; // Ensure this CSS file is updated for styling
import VerticalNavbarAdmin from './VerticalNavbarAdmin';
import HorizontalNavbarAdmin from './HorizontalNavbarAdmin';
import config from '../functions/config';

const ViewUsers = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                // const response = await fetch('http://localhost:8000/api/users-with-payments/');
                const response = await fetch(`${config.apiUrl}users-with-payments/`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUsers(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    return (
        <div className="view-users-container">
            <HorizontalNavbarAdmin />
            <div className="main-content">
                <VerticalNavbarAdmin />
                <div className="view-users-content">
                    <h1>Users with Payments</h1>
                    {loading && <p>Loading...</p>}
                    {error && <p>Error: {error}</p>}
                    {users.length === 0 && !loading && <p>No users found</p>}
                    {users.length > 0 && (
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user, index) => (
                                    <tr key={index}>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.mobile}</td>
                                        <td>{user.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ViewUsers;
