import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UserNavbar from './UserNavbar';
import './UserDetails.css';
import config from '../functions/config';

const UserDetails = () => {
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [chatRequested, setChatRequested] = useState(false);
  const [blocked, setBlocked] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        // const url = `http://localhost:8000/api/user_profile_detail/${id}/`.trim();
        const url = `${config.apiUrl}user_profile_detail/${id}/`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Error ${response.status}: ${errorText}`);
        }

        const data = await response.json();
        setUserDetails(data);

        // Check local storage for chat request status
        const chatRequestStatus = localStorage.getItem(`chatRequested_${id}`);
        if (chatRequestStatus === 'true') {
          setChatRequested(true);
        }
        
        // Check local storage for blocked status
        const blockedStatus = localStorage.getItem(`blocked_${id}`);
        if (blockedStatus === 'true') {
          setBlocked(true);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [id]);

  const requestChat = async () => {
    try {
      // const url = `http://localhost:8000/api/request_chat/${id}/`;
      const url = `${config.apiUrl}request_chat/${id}/`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ message: 'Requesting to chat' }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to request chat: ${errorText}`);
      }

      setChatRequested(true);
      // Store chat request status in local storage
      localStorage.setItem(`chatRequested_${id}`, 'true');
    } catch (err) {
      setError(err.message);
    }
  };

  const blockUser = async () => {
    try {
      // const url = `http://localhost:8000/api/block_user/${id}/`;
      const url = `${config.apiUrl}block_user/${id}/`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to block user: ${errorText}`);
      }

      setBlocked(true);
      // Store blocked status in local storage
      localStorage.setItem(`blocked_${id}`, 'true');
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) return <div>Loading user details...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!userDetails) return <div>No user data found.</div>;

  return (
    <div className="user-details-container">
      <UserNavbar />
      <div className="user-details-card">
        <div className="user-header">
          {/* <img src={`http://localhost:8000${userDetails.profile_picture_1}`} alt="Profile" className="profile-picture" /> */}
          <img src={`${config.baseUrl}${userDetails.profile_picture_1}`} alt="Profile" className="profile-picture" />
          <h3>{userDetails.name}</h3>
        </div>
        <div className="user-details">
          <p>Email: {userDetails.email}</p>
          <p>Gender: {userDetails.gender}</p>
          <p>Height: {userDetails.height}</p>
          <p>Designation: {userDetails.designation}</p>
          <p>Place: {userDetails.place}</p>
          <p>District: {userDetails.district}</p>
          <p>Mobile: {userDetails.mobile}</p>
          <p>Religion: {userDetails.religion}</p>
          <p>Caste: {userDetails.caste}</p>
          <p>Marriage Level: {userDetails.marriage_level}</p>
          <p>Mother's Name: {userDetails.mother_name}</p>
          <p>Mother's Job: {userDetails.mother_job}</p>
          <p>Father's Name: {userDetails.father_name}</p>
          <p>Father's Job: {userDetails.father_job}</p>

          {/* Sibling Details */}
          <div className="sibling-details">
            <h4>Sibling Details</h4>
            {[1, 2, 3].map(index => {
              const siblingName = userDetails[`sibling_${index}_name`];
              const siblingMarried = userDetails[`sibling_${index}_married`];
              return (
                siblingName || siblingMarried ? (
                  <div key={index} className="sibling">
                    <p>Sibling Name: {siblingName || 'N/A'}</p>
                    <p>Marital Status: {siblingMarried || 'N/A'}</p>
                  </div>
                ) : null
              );
            })}
            {(!userDetails.sibling_1_name && !userDetails.sibling_2_name && !userDetails.sibling_3_name) && (
              <p>No siblings available.</p>
            )}
          </div>

          {/* Profile Pictures */}
          {/* <div className="profile-pictures">
            <h4>Profile Pictures</h4>
            {Array.from({ length: 5 }, (_, index) => userDetails[`profile_picture_${index + 1}`]).map((pic, idx) => (
              pic ? <img key={idx} src={`http://localhost:8000${pic}`} alt={`Profile ${idx + 1}`} /> : null
            ))}
          </div> */}
          <div className="profile-pictures">
            <h4>Profile Pictures</h4>
            {Array.from({ length: 5 }, (_, index) => userDetails[`profile_picture_${index + 1}`]).map((pic, idx) => (
              pic ? <img key={idx} src={`${config.baseUrl}${pic}`} alt={`Profile ${idx + 1}`} /> : null
            ))}
          </div>

          {/* Chat Request Button */}
          {!chatRequested ? (
            <button onClick={requestChat} className="request-chat-button">
              Request Chat
            </button>
          ) : (
            <p>Chat request sent!</p>
          )}

          {/* Block User Button */}
          {!blocked ? (
            <button onClick={blockUser} className="block-user-button">
              Block User
            </button>
          ) : (
            <p>User Blocked</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
