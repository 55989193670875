import React, { useState, useEffect } from 'react';
import CombinedNavbar from './CombinedNavbar';
import './ApprovedUsers.css'; // Make sure to create this file for styles
import config from '../functions/config';

const ApprovedUsers = () => {
  const [approvedUsers, setApprovedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchApprovedUsers = async () => {
      try {
        // const response = await fetch('http://localhost:8000/api/approved-users/');
        const response = await fetch(`${config.apiUrl}approved-users/`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setApprovedUsers(data);
      } catch (error) {
        console.error('Error fetching approved users:', error);
        setError('Failed to load approved users.');
      } finally {
        setLoading(false);
      }
    };

    fetchApprovedUsers();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="app">
      <CombinedNavbar />
      <div className="main-content">
        <div className="content-area">
          <h1>Approved Users</h1>
          {approvedUsers.length > 0 ? (
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    {['ID', 'Email', 'Name', 'Gender', 'District', 'Mobile', 'Religion', 'Marriage Level', 'Status', 'Package ID', 'Start Date', 'End Date', 'Amount'].map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {approvedUsers.map(user => (
                    <tr key={user.id}>
                      <td>{user.id}</td>
                      <td>{user.email}</td>
                      <td>{user.name}</td>
                      <td>{user.gender}</td>
                      <td>{user.district}</td>
                      <td>{user.mobile}</td>
                      <td>{user.religion}</td>
                      <td>{user.marriage_level}</td>
                      <td>{user.status}</td>
                      <td>{user.payment_details?.package_id}</td>
                      <td>{user.payment_details?.start_date}</td>
                      <td>{user.payment_details?.end_date}</td>
                      <td>{user.payment_details?.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No approved users.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApprovedUsers;
