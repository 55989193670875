import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './HorizontalNavbarAdmin.css'; // Ensure this file contains the necessary styling

const HorizontalNavbar = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogout = () => {
    navigate('/'); // Redirect to home page or login page
  };

  return (
    <div className="horizontal-navbar">
      <h3>Welcome Admin</h3>
      <button className="logout-button" onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default HorizontalNavbar;
