import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './VerticalNavbarAdmin.css';
import config from '../functions/config';

const VerticalNavbar = () => {
  const [unapprovedCount, setUnapprovedCount] = useState(0);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  useEffect(() => {
    const fetchUnapprovedCount = async () => {
      try {
        // const response = await fetch('http://localhost:8000/api/unapproved-users/count');
        const response = await fetch(`${config.apiUrl}unapproved-users/count`);
        const data = await response.json();
        setUnapprovedCount(data.count);
      } catch (error) {
        console.error("Error fetching unapproved users count:", error);
      }
    };

    fetchUnapprovedCount();
  }, []);

  const toggleNavbar = () => {
    setIsNavbarOpen(prevState => !prevState);
  };

  const handleLinkClick = () => {
    setIsNavbarOpen(false);
  };

  return (
    <div>
      <div className="navbar-toggle-icon" onClick={toggleNavbar}>
        <FontAwesomeIcon icon={isNavbarOpen ? faTimes : faBars} />
      </div>

      <div className={`vertical-navbar ${isNavbarOpen ? 'open' : ''}`}>
        <ul>
          <li>
            <a href="/admin" onClick={handleLinkClick}>
              Admin Dashboard
              {unapprovedCount > 0 && (
                <span className="notification-badge">{unapprovedCount}</span>
              )}
            </a>
          </li>
          <li>
            <a href="/userpackagesadmin" onClick={handleLinkClick}>Users Package</a>
          </li>
          <li>
            <a href="/unapproved-users" onClick={handleLinkClick}>
              Approve/Disapprove
              {unapprovedCount > 0 && (
                <span className="notification-badge">{unapprovedCount}</span>
              )}
            </a>
          </li>
          <li>
            <a href="/approved-users" onClick={handleLinkClick}>Approved Users</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default VerticalNavbar;
