import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import './EditProfile .css'; 
import UserNavbar from './UserNavbar';
import config from '../functions/config';

const EditProfile = () => {
    const navigate = useNavigate(); 
    const [formData, setFormData] = useState({
        name: '',
        gender: '',
        age: '',
        height: '',
        education: '',
        job: '',
        place: '',
        district: '',
        email: '',
        mobile: '',
        religion: '',
        caste: '',
        marriage_level: '',
        mother_name: '',
        mother_job: '',
        father_name: '',
        father_job: '',
        siblings: [],
        profilePictures: []
    });
    const [errors, setErrors] = useState({});
    // const baseUrl = 'http://localhost:8000'; // Adjust as needed

    useEffect(() => {
        const fetchUserProfile = async () => {
            const token = localStorage.getItem('token');
            // const response = await fetch('http://localhost:8000/api/user_profile/', {
                const response = await fetch(`${config.apiUrl}user_profile/`, {
                headers: {
                    'Authorization': `Token ${token}`,
                }
            });
            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setFormData((prev) => ({
                    ...prev,
                    name: data.name || '',
                    gender: data.gender || '',
                    age: data.age || '',
                    height: data.height || '',
                    education: data.education || '',
                    job: data.job || '',
                    place: data.place || '',
                    district: data.district || '',
                    email: data.email || '',
                    mobile: data.mobile || '',
                    religion: data.religion || '',
                    caste: data.caste || '',
                    marriage_level: data.marriage_level || '',
                    mother_name: data.mother_name || '',
                    mother_job: data.mother_job || '',
                    father_name: data.father_name || '',
                    father_job: data.father_job || '',
                    siblings: [
                        { name: data.sibling_1_name || '', married: data.sibling_1_married || '' },
                        { name: data.sibling_2_name || '', married: data.sibling_2_married || '' },
                        { name: data.sibling_3_name || '', married: data.sibling_3_married || '' }
                    ].filter(sibling => sibling.name || sibling.married),
                    profilePictures: [
                        // `${baseUrl}${data.profile_picture_1}` || '',
                        // `${baseUrl}${data.profile_picture_2}` || '',
                        // `${baseUrl}${data.profile_picture_3}` || ''
                        `${config.baseUrl}${data.profile_picture_1}` || '',
                        `${config.baseUrl}${data.profile_picture_2}` || '',
                        `${config.baseUrl}${data.profile_picture_3}` || ''
                    ].filter(Boolean)
                }));
            } else {
                console.error('Failed to fetch user profile');
            }
        };
        
        fetchUserProfile();
    }, []);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSiblingsChange = (index, e) => {
        const { name, value } = e.target;
        const newSiblings = [...formData.siblings];
        newSiblings[index][name] = value;
        setFormData((prev) => ({
            ...prev,
            siblings: newSiblings
        }));
    };

    const handleAddSibling = () => {
        setFormData((prev) => ({
            ...prev,
            siblings: [...prev.siblings, { name: '', married: '' }]
        }));
    };

    const handleRemoveSibling = (index) => {
        const newSiblings = formData.siblings.filter((_, i) => i !== index);
        setFormData((prev) => ({
            ...prev,
            siblings: newSiblings
        }));
    };

    const handleFileChange = (e) => {
        if (e.target.files) {
            const newFilesArray = Array.from(e.target.files).map(file => URL.createObjectURL(file));
            setFormData((prev) => ({
                ...prev,
                profilePictures: [...prev.profilePictures, ...newFilesArray]
            }));
        }
    };
    
    const validateForm = () => {
        const newErrors = {};
        if (formData.profilePictures.length < 3) {
            newErrors.profilePictures = "Please upload at least three profile pictures.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validateForm()) {
            return;
        }
    
        const formDataToSend = new FormData();
    
        for (const key in formData) {
            if (key === 'profilePictures') {
                formData.profilePictures.forEach((picture, index) => {
                    // Assuming you have the file objects stored initially
                    const file = document.getElementById('profilePictures').files[index];
                    if (file) {
                        formDataToSend.append(`profile_picture_${index + 1}`, file);
                    }
                });
            } else if (key === 'siblings') {
                formData[key].forEach((sibling, index) => {
                    formDataToSend.append(`sibling_${index + 1}_name`, sibling.name);
                    formDataToSend.append(`sibling_${index + 1}_married`, sibling.married);
                });
            } else {
                formDataToSend.append(key, formData[key]);
            }
        }
    
        try {
            // const response = await fetch('http://localhost:8000/api/update_profile/', {
                const response = await fetch(`${config.apiUrl}update_profile/`, {
                method: 'POST',
                body: formDataToSend,
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                }
            });
    
            if (response.ok) {
                const result = await response.json();
                alert(result.message || 'Profile updated successfully');
                navigate('/profile');
            } else {
                const errorResult = await response.json();
                setErrors({ form: errorResult.error || 'Update failed' });
            }
        } catch (error) {
            setErrors({ form: 'Network error. Please try again.' });
        }
    };
    
    useEffect(() => {
        return () => {
            formData.profilePictures.forEach(picture => URL.revokeObjectURL(picture));
        };
    }, [formData.profilePictures]);

    return (
        <div className="registration-form-overlay">
            <UserNavbar /><br></br>
            <div className="registration-form-container">
                
                {errors.form && <div className="error-message">{errors.form}</div>}
                <form onSubmit={handleSubmit}>

                <h2>Edit Profile...</h2><br></br>
                <div className="form-row">
                            
                <       div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} placeholder="Name" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="gender">Gender</label>
                            <select id="gender" name="gender" value={formData.gender} onChange={handleChange} required>
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                </div>
                <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="age">Age</label>
                            <input type="text" id="age" name="age" value={formData.age} onChange={handleChange} placeholder="Age" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="height">Height</label>
                            <input type="text" id="height" name="height" value={formData.height} onChange={handleChange} placeholder="Height" required />
                        </div>
                </div>
                <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="education">Education</label>
                            <input type="text" id="education" name="education" value={formData.education} onChange={handleChange} placeholder="Education" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="job">Job</label>
                            <input type="text" id="job" name="job" value={formData.job} onChange={handleChange} placeholder="Job" required />
                        </div>
                </div>
                <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="place">Place</label>
                            <input type="text" id="place" name="place" value={formData.place} onChange={handleChange} placeholder="Place" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="district">District</label>
                            <input type="text" id="district" name="district" value={formData.district} onChange={handleChange} placeholder="District" required />
                        </div>
                </div>
                <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input 
                                    type="email" 
                                    id="email" 
                                    name="email" 
                                    value={formData.email} 
                                    onChange={handleChange} 
                                    placeholder="Email" 
                                    required 
                                />
                                {errors.email && <div className="error-message">{errors.email}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="mobile">Mobile Number</label>
                                <input 
                                    type="number" 
                                    id="mobile" 
                                    name="mobile" 
                                    value={formData.mobile} 
                                    onChange={handleChange} 
                                    placeholder="Mobile Number" 
                                    required 
                                />
                                {errors.mobile && <div className="error-message">{errors.mobile}</div>}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="religion">Religion</label>
                                <select id="religion" name="religion" value={formData.religion} onChange={handleChange} required>
                                    <option value="">Select Religion</option>
                                    <option value="Hindu">Hindu</option>
                                    <option value="Muslim">Muslim</option>
                                    <option value="Christian">Christian</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="caste">Caste</label>
                                <select id="caste" name="caste" value={formData.caste} onChange={handleChange} required>
                                    <option value="">Select Caste</option>
                                    <option value="General">General</option>
                                    <option value="OBC">OBC</option>
                                    <option value="SC">SC</option>
                                    <option value="ST">ST</option>
                                </select>
                            </div>
                        </div>

                    
                      

                        <div className="form-row">
                            
                            <div className="form-group">
                                <label htmlFor="mother_name">Mother's Name</label>
                                <input type="text" id="mother_name" name="mother_name" value={formData.mother_name} onChange={handleChange} placeholder="Mother's Name" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="mother_job">Mother's Job</label>
                                <input type="text" id="mother_job" name="mother_job" value={formData.mother_job} onChange={handleChange} placeholder="Mother's Job" required />
                            </div>
                        </div>
                    

                        <div className="form-row">
                            
                            <div className="form-group">
                                <label htmlFor="father_name">Father's Name</label>
                                <input type="text" id="father_name" name="father_name" value={formData.father_name} onChange={handleChange} placeholder="Father's Name" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="father_job">Father's Job</label>
                                <input type="text" id="father_job" name="father_job" value={formData.father_job} onChange={handleChange} placeholder="Father's Job" required />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="marriage_level">Marriage Level</label>
                                <select id="marriage_level" name="marriage_level" value={formData.marriage_level} onChange={handleChange} required>
                                    <option value="">Select Marriage Level</option>
                                    <option value="Newly Married">Newly Married</option>
                                    <option value="Remarriage">Remarriage</option>
                                </select>
                            </div>
                            
                        </div>
                    
                        <legend>Sibling Details</legend>
                        {formData.siblings.map((sibling, index) => (
                            <div key={index} className="sibling-form form-row">
                                <div className="form-group">
                                    <label htmlFor={`siblingName-${index}`}>Sibling's Name</label>
                                    <input 
                                        type="text" 
                                        id={`siblingName-${index}`} 
                                        name="name" 
                                        value={sibling.name} 
                                        onChange={(e) => handleSiblingsChange(index, e)} 
                                        placeholder="Sibling's Name" 
                                        required 
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor={`siblingMarried-${index}`}>Marital Status</label>
                                    <select 
                                        id={`siblingMarried-${index}`} 
                                        name="married" 
                                        value={sibling.married} 
                                        onChange={(e) => handleSiblingsChange(index, e)} 
                                        required
                                    >
                                        <option value="">Select</option>
                                        <option value="Married">Married</option>
                                        <option value="Unmarried">Unmarried</option>
                                    </select>
                                </div>
                                <div className="button-group">
                                    <button type="button" className="remove-sibling" onClick={() => handleRemoveSibling(index)}>Remove</button>
                                    <button type="button" className="add-sibling" onClick={handleAddSibling}>Add </button>
                                </div>
                            </div>
                        ))}
                    

                  

                    
                    <fieldset>
                        <div className="form-group">
                            <label>Profile Pictures</label>
                            <div className="profile-picture-preview">
                                {formData.profilePictures.length > 0 ? (
                                    formData.profilePictures.map((picture, index) => (
                                        <img 
                                            key={index} 
                                            src={picture} 
                                            alt={`Profile ${index + 1}`} 
                                            style={{ width: '100px', height: '100px', objectFit: 'cover' }} 
                                            onError={(e) => {
                                                e.target.onerror = null; 
                                                e.target.src = 'path/to/placeholder/image.png'; // Fallback image
                                            }}
                                        />
                                    ))
                                ) : (
                                    <div>No profile pictures uploaded.</div>
                                )}
                            </div>
                            <input 
                                type="file" 
                                id="profilePictures" 
                                multiple 
                                accept="image/*" 
                                onChange={handleFileChange} 
                            />
                            {errors.profilePictures && <div className="error-message">{errors.profilePictures}</div>}
                        </div>
                    </fieldset>

                    <button type="submit">Update Profile</button>
                </form>
            </div>
        </div>
    );
};

export default EditProfile;
