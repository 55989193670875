import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import UserNavbar from './UserNavbar';
import StatsCard from './StatsCard';
import './UserPage.css';
import config from '../functions/config';

const UserPage = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [stats, setStats] = useState({
    profileViews: 0,
    chatRequests: 0,
    blockedUsers: 0,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError('User not authenticated');
          setLoading(false);
          return;
        }

        // const response = await axios.get('http://localhost:8000/api/user_profile/', {
          const response = await axios.get(`${config.apiUrl}user_profile/`, {
          headers: { 'Authorization': `Token ${token}` },
        });

        setUserDetails(response.data);
      } catch (error) {
        setError('Error fetching user details');
      } finally {
        setLoading(false);
      }
    };

    const fetchProfileViewCount = async () => {
      try {
        const token = localStorage.getItem('token');
        // const response = await axios.get('http://localhost:8000/api/profile_viewers/count/', {
          const response = await axios.get(`${config.apiUrl}profile_viewers/count/`, {
          headers: { 'Authorization': `Token ${token}` },
        });
        setStats(prevStats => ({
          ...prevStats,
          profileViews: response.data.count || 0,
        }));
      } catch (error) {
        console.error('Error fetching profile view count:', error.message);
      }
    };

    const fetchChatRequestCount = async () => {
      try {
        const token = localStorage.getItem('token');
        // const response = await axios.get('http://localhost:8000/api/chat_request_count/', {
          const response = await axios.get(`${config.apiUrl}chat_request_count/`, {
          headers: { 'Authorization': `Token ${token}` },
        });
        setStats(prevStats => ({
          ...prevStats,
          chatRequests: response.data.chat_requests || 0,
        }));
      } catch (error) {
        console.error('Error fetching chat request count:', error.message);
      }
    };

    const fetchBlockedUserCount = async () => {
      try {
        const token = localStorage.getItem('token');
        // const response = await axios.get('http://localhost:8000/api/blocked_user_count/', {
          const response = await axios.get(`${config.apiUrl}blocked_user_count/`, {
          headers: { 'Authorization': `Token ${token}` },
        });
        setStats(prevStats => ({
          ...prevStats,
          blockedUsers: response.data.blocked_users || 0,
        }));
      } catch (error) {
        console.error('Error fetching blocked user count:', error.message);
      }
    };

    fetchUserProfile();
    fetchProfileViewCount();
    fetchChatRequestCount();
    fetchBlockedUserCount(); // Fetch the blocked user count
  }, []);

  if (loading) return <div className="loading">Loading profile...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!userDetails) return <div>No profile data</div>;

  return (
    <div className="user-page">
      
      <UserNavbar />
      <div className="user-content">
        <h1>User Dashboard</h1>
        <p>Welcome, {userDetails.name}!</p>

        <h2>Dashboard Statistics</h2>
        <div className="stats-container">
          <StatsCard title="Profile Views" value={stats.profileViews} color="#FF5722" />
          <StatsCard title="Chat Requests" value={stats.chatRequests} color="#2196F3" />
          <StatsCard title="Blocked Users" value={stats.blockedUsers} color="#FF9800" />
        </div>

        <h2>Profile Details</h2>
        <div className="profile-card">
          {userDetails.profile_picture_1 && (
            // <img
            //   src={`http://localhost:8000${userDetails.profile_picture_1}`}
            //   alt="Profile"
            //   className="profile-picture"
            //   onError={(e) => { e.target.onerror = null; e.target.src = 'path/to/default/image.png'; }}
            // />
            <img
            src={`${config.baseUrl}${userDetails.profile_picture_1}`}
            alt="Profile"
            className="profile-picture"
            onError={(e) => { e.target.onerror = null; e.target.src = 'path/to/default/image.png'; }}
          />
          )}
          <p>Email: {userDetails.email}</p>
          <p>Gender: {userDetails.gender}</p>
          <p>Age: {userDetails.age}</p>
          <p>Job: {userDetails.job}</p>
          <p>Mobile: {userDetails.mobile}</p>

          <div className="button-container">
            <button 
              className="action-button" 
              onClick={() => navigate('/profile')}
            >
              View Profile
            </button>
            <button 
              className="action-button" 
              onClick={() => navigate('/editprofile')}
            >
              Edit Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPage;
