import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import './RegistrationForm.css'; 
import Navbar from './Navbar'; 
import config from '../functions/config';

const RegistrationForm = () => {
    const navigate = useNavigate(); 
    const [formData, setFormData] = useState({
        name: '',
        gender: '',
        age: '',
        height: '',
        education: '',
        job: '',
        place: '',
        district: '',
        email: '',
        mobile: '',
        religion: '',
        caste: '',
        marriageLevel: '',
        motherName: '',
        motherJob: '',
        fatherName: '',
        fatherJob: '',
        siblings: [{ name: '', married: '' }],
        profilePictures: []
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSiblingsChange = (index, e) => {
        const { name, value } = e.target;
        const newSiblings = [...formData.siblings];
        newSiblings[index][name] = value;
        setFormData((prev) => ({
            ...prev,
            siblings: newSiblings
        }));
    };

    const handleAddSibling = () => {
        setFormData((prev) => ({
            ...prev,
            siblings: [...prev.siblings, { name: '', married: '' }]
        }));
    };

    const handleRemoveSibling = (index) => {
        const newSiblings = formData.siblings.filter((_, i) => i !== index);
        setFormData((prev) => ({
            ...prev,
            siblings: newSiblings
        }));
    };

    // const handleFileChange = (e) => {
    //     if (e.target.files) {
    //         const newFilesArray = Array.from(e.target.files);
    //         setFormData((prev) => ({
    //             ...prev,
    //             profilePictures: [...prev.profilePictures, ...newFilesArray]
    //         }));
    //     }
    // };
    const handleFileChange = (e) => {
        if (e.target.files) {
            const newFilesArray = Array.from(e.target.files);
            if (newFilesArray.length + formData.profilePictures.length > 3) {
                setErrors((prev) => ({
                    ...prev,
                    profilePictures: "You can upload a maximum of three profile pictures."
                }));
            } else {
                setFormData((prev) => ({
                    ...prev,
                    profilePictures: [...prev.profilePictures, ...newFilesArray]
                }));
                setErrors((prev) => ({ ...prev, profilePictures: null })); // Clear error if valid
            }
        }
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const validateMobile = (mobile) => {
        const mobilePattern = /^\d{10}$/; // Adjust the regex based on your requirements
        return mobilePattern.test(mobile);
    };

    const validateForm = () => {
        const newErrors = {};
        if (formData.profilePictures.length < 3) {
            newErrors.profilePictures = "Please upload at least three profile pictures.";
        }
        if (!validateEmail(formData.email)) {
            newErrors.email = "Please enter a valid email address.";
        }
        if (!validateMobile(formData.mobile)) {
            newErrors.mobile = "Please enter a valid mobile number (10 digits).";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validateForm()) {
            return;
        }
    
        const formDataToSend = new FormData();
    
        for (const key in formData) {
            if (key === 'profilePictures') {
                formData[key].forEach((file, index) => {
                    formDataToSend.append(`profilePictures_${index}`, file);
                });
            } else if (key === 'siblings') {
                formDataToSend.append('siblings[]', JSON.stringify(formData[key]));
            } else {
                formDataToSend.append(key, formData[key]);
            }
        }
    
        try {
            const response = await fetch(`${config.baseUrl}/register/`, {
                method: 'POST',
                body: formDataToSend,
            });
    
            if (response.ok) {
                const result = await response.json();
                alert(result.message || 'Registration successful');
                navigate('/payment', { state: { userId: result.userId } });
            } else {
                const errorResult = await response.json();
                setErrors({ form: errorResult.error || 'Registration failed' });
            }
        } catch (error) {
            setErrors({ form: 'Network error. Please try again.' });
        }
    };
    
    return (
        <div className="registration-form-overlays">
            <Navbar />
            <div className="registration-form-containers">
                {/* <h2>Registration Form</h2> */}
                
                <form onSubmit={handleSubmit}>
                <h2>Register Here... </h2><br></br>
                {errors.form && <div className="error-message">{errors.form}</div>}
                        <div className="form-row">
                            
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} placeholder="Name" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="gender">Gender</label>
                                <select id="gender" name="gender" value={formData.gender} onChange={handleChange} required>
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                        </div>
                    

                    
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="age">Age</label>
                                <input type="number" id="age" name="age" value={formData.age} onChange={handleChange} placeholder="Age" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="height">Height</label>
                                <input type="number" id="height" name="height" value={formData.height} onChange={handleChange} placeholder="Height" required />
                            </div>
                        </div>
                    

                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="education">Education</label>
                                <input type="text" id="education" name="education" value={formData.education} onChange={handleChange} placeholder="Education" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="job">Job</label>
                                <input type="text" id="job" name="job" value={formData.job} onChange={handleChange} placeholder="Job" required />
                            </div>
                        </div>
                   
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="place">Place</label>
                                <input type="text" id="place" name="place" value={formData.place} onChange={handleChange} placeholder="Place" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="district">District</label>
                                <input type="text" id="district" name="district" value={formData.district} onChange={handleChange} placeholder="District" required />
                            </div>
                        </div>
                    
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input 
                                    type="email" 
                                    id="email" 
                                    name="email" 
                                    value={formData.email} 
                                    onChange={handleChange} 
                                    placeholder="Email" 
                                    required 
                                />
                                {errors.email && <div className="error-message">{errors.email}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="mobile">Mobile Number</label>
                                <input 
                                    type="number" 
                                    id="mobile" 
                                    name="mobile" 
                                    value={formData.mobile} 
                                    onChange={handleChange} 
                                    placeholder="Mobile Number" 
                                    required 
                                />
                                {errors.mobile && <div className="error-message">{errors.mobile}</div>}
                            </div>
                        </div>
                    

                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="religion">Religion</label>
                                <select id="religion" name="religion" value={formData.religion} onChange={handleChange} required>
                                    <option value="">Select Religion</option>
                                    <option value="Hindu">Hindu</option>
                                    <option value="Muslim">Muslim</option>
                                    <option value="Christian">Christian</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="caste">Caste</label>
                                <select id="caste" name="caste" value={formData.caste} onChange={handleChange} required>
                                    <option value="">Select Caste</option>
                                    <option value="General">General</option>
                                    <option value="OBC">OBC</option>
                                    <option value="SC">SC</option>
                                    <option value="ST">ST</option>
                                </select>
                            </div>
                        </div>
                    

                        <div className="form-row">
                            
                            <div className="form-group">
                                <label htmlFor="motherName">Mother's Name</label>
                                <input type="text" id="motherName" name="motherName" value={formData.motherName} onChange={handleChange} placeholder="Mother's Name" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="motherJob">Mother's Job</label>
                                <input type="text" id="motherJob" name="motherJob" value={formData.motherJob} onChange={handleChange} placeholder="Mother's Job" required />
                            </div>
                        </div>
                    

                        <div className="form-row">
                            
                            <div className="form-group">
                                <label htmlFor="fatherJob">Father's Job</label>
                                <input type="text" id="fatherJob" name="fatherJob" value={formData.fatherJob} onChange={handleChange} placeholder="Father's Job" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="fatherName">Father's Name</label>
                                <input type="text" id="fatherName" name="fatherName" value={formData.fatherName} onChange={handleChange} placeholder="Father's Name" required />
                            </div>
                        </div>
                    

                        <div className="form-row">
                        <div className="form-group">
                                <label htmlFor="marriageLevel">Marriage Level</label>
                                <select id="marriageLevel" name="marriageLevel" value={formData.marriageLevel} onChange={handleChange} required>
                                    <option value="">Select Marriage Level</option>
                                    <option value="Newly Married">Newly Married</option>
                                    <option value="Remarriage">Remarriage</option>
                                </select>
                            </div>
                            
                        </div>
                    
                        <legend>Sibling Details</legend>
                        {formData.siblings.map((sibling, index) => (
                            <div key={index} className="sibling-form form-row">
                                <div className="form-group">
                                    <label htmlFor={`siblingName-${index}`}>Sibling's Name</label>
                                    <input 
                                        type="text" 
                                        id={`siblingName-${index}`} 
                                        name="name" 
                                        value={sibling.name} 
                                        onChange={(e) => handleSiblingsChange(index, e)} 
                                        placeholder="Sibling's Name" 
                                        required 
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor={`siblingMarried-${index}`}>Marital Status</label>
                                    <select 
                                        id={`siblingMarried-${index}`} 
                                        name="married" 
                                        value={sibling.married} 
                                        onChange={(e) => handleSiblingsChange(index, e)} 
                                        required
                                    >
                                        <option value="">Select</option>
                                        <option value="Married">Married</option>
                                        <option value="Unmarried">Unmarried</option>
                                    </select>
                                </div>
                                <div className="button-group">
                                    <button type="button" className="remove-sibling" onClick={() => handleRemoveSibling(index)}>Remove</button>
                                    <button type="button" className="add-sibling" onClick={handleAddSibling}>Add </button>
                                </div>
                            </div>
                        ))}
                    
                        <div className="form-group">
                            <label htmlFor="profilePictures">Profile Pictures</label>
                            <input 
                                type="file" 
                                id="profilePictures" 
                                multiple 
                                accept="image/*" 
                                onChange={handleFileChange} 
                                required 
                            />
                            {errors.profilePictures && <div className="error-message">{errors.profilePictures}</div>}
                        </div>
                    

                    <button type="submit">Register</button>
                </form>
            </div>
        </div>
    );
};

export default RegistrationForm;
