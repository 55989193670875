import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import config from '../functions/config';


const NavbarContainer = styled.div`
  position: fixed;
  top: 60px; /* Below the topbar */
  left: 0;
  width: 200px;
  height: calc(100% - 60px);
  background-color: #333; /* Dark Grey */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: transform 0.3s ease;
  transform: ${({ isNavVisible }) => (isNavVisible ? 'translateX(0)' : 'translateX(-200px)')};
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow-y: auto;

  @media (min-width: 768px) {
    transform: translateX(0);
  }
`;

const Topbar = styled.div`
  width: 100%;
  height: 60px;
  background-color: #9b2929; /* Dark Red */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  z-index: 1100;
  position: fixed;
  top: 0;
  left: 0;

  h2 {
    color: white;
    margin: 0;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #f5f5f5; /* Light Grey */
  padding: 10px 20px; /* Adjusted for horizontal layout */
  text-align: center;
  width: auto; /* Allow width to auto based on content */
  transition: background-color 0.3s ease;
  margin: 5px 0; /* Space between links */

  &:hover {
    background-color: #7e7a7a; /* Hover color */
  }
`;

const SearchForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ToggleButton = styled.button`
  background-color: #9b2929; /* Match topbar color */
  border: none;
  color: white;
  padding: 15px;
  cursor: pointer;
  font-size: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);

  @media (min-width: 768px) {
    display: none;
  }
`;

const LogoutButton = styled.button`
  color: white;
  background-color: #e74c3c; /* Red */
  border: none;
  cursor: pointer;
  padding: 15px;
  text-align: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c0392b; /* Darker Red */
  }
`;

const SearchButton = styled.button`
  background-color: #4CAF50; /* Green */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;

  &:hover {
    background-color: #45a049; /* Darker Green */
  }
`;
const NotificationCount = styled.span`
  background-color: #e74c3c; /* Red background */
  color: white;
  border-radius: 12px;
  padding: 2px 6px; /* Adjust padding for better appearance */
  margin-left: 5px; /* Space between link text and count */
  font-size: 14px; /* Adjust font size */
`;

const Navbar = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [searchType, setSearchType] = useState('id');
  const [searchQuery, setSearchQuery] = useState('');
  const [ageRange, setAgeRange] = useState({ min: '', max: '' });
  const [caste, setCaste] = useState('');
  const [religion, setReligion] = useState('');
  const [marriageLevel, setMarriageLevel] = useState('');
  const [education, setEducation] = useState('');
  const [district, setDistrict] = useState('');
  const [job, setJob] = useState('');
  const [gender, setGender] = useState('');
  const navigate = useNavigate();
  const [viewerCount, setViewerCount] = useState(0);
  

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
    setSearchQuery('');
    setAgeRange({ min: '', max: '' });
    setCaste('');
    setReligion('');
    setMarriageLevel('');
    setEducation('');
    setDistrict('');
    setJob('');
    setGender('');
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    const queryParams = new URLSearchParams({
      minAge: ageRange.min,
      maxAge: ageRange.max,
      caste,
      religion,
      marriageLevel,
      education,
      district,
      job,
      gender,
      id: searchType === 'id' ? searchQuery : undefined,
    }).toString();

    if (searchType === 'id' && searchQuery) {
      navigate(`/user_profile_detail/${searchQuery}`);
    } else {
      await performSearch(queryParams);
    }
  };

  const performSearch = async (queryParams) => {
    const token = localStorage.getItem('token');
    try {
      // const response = await fetch(`http://localhost:8000/api/search_users?${queryParams}`, {
        const response = await fetch(`${config.apiUrl}search_users?${queryParams}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch users: ${response.status} - ${errorText}`);
      }

      const data = await response.json();
      navigate('/search_results', { state: { users: data } });
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  useEffect(() => {
    const fetchViewerCount = async () => {
      const token = localStorage.getItem('token');
      try {
        // const response = await fetch('http://localhost:8000/api/profile_viewers/count/', {
          const response = await fetch(`${config.apiUrl}profile_viewers/count/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setViewerCount(data.count);
        } else {
          console.error("Failed to fetch viewer count");
        }
      } catch (error) {
        console.error("Error fetching viewer count:", error);
      }
    };

    fetchViewerCount();
  }, []);

  return (
    <>
      <Topbar>
        <h2>User</h2>
        <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
        <ToggleButton onClick={toggleNav}>
          {isNavVisible ? '✖' : '☰'}
        </ToggleButton>
      </Topbar>

      <NavbarContainer isNavVisible={isNavVisible}>
        <SearchForm onSubmit={handleSearchSubmit}>
          <select value={searchType} onChange={handleSearchTypeChange}>
            <option value="id">ID Search</option>
            <option value="regular">Regular Search</option>
            <option value="advanced">Advanced Search</option>
          </select>
          {searchType === 'id' ? (
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search by ID..."
              required
            />
          ) : (
            <>
              <select value={gender} onChange={(e) => setGender(e.target.value)}>
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              <input
                type="number"
                value={ageRange.min}
                onChange={(e) => setAgeRange({ ...ageRange, min: e.target.value })}
                placeholder="Min Age"
              />
              <input
                type="number"
                value={ageRange.max}
                onChange={(e) => setAgeRange({ ...ageRange, max: e.target.value })}
                placeholder="Max Age"
              />
              <input
                type="text"
                value={caste}
                onChange={(e) => setCaste(e.target.value)}
                placeholder="Caste"
              />
              <input
                type="text"
                value={religion}
                onChange={(e) => setReligion(e.target.value)}
                placeholder="Religion"
              />
              <input
                type="text"
                value={marriageLevel}
                onChange={(e) => setMarriageLevel(e.target.value)}
                placeholder="Marriage Level"
              />
              <input
                type="text"
                value={education}
                onChange={(e) => setEducation(e.target.value)}
                placeholder="Education"
              />
              <input
                type="text"
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
                placeholder="District"
              />
              <input
                type="text"
                value={job}
                onChange={(e) => setJob(e.target.value)}
                placeholder="Job"
              />
            </>
          )}
          <SearchButton type="submit">Search</SearchButton>
        </SearchForm>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
        <NavLink to="/user">User Dashboard</NavLink>
          <NavLink to="/profile">View Profile</NavLink>
          <NavLink to="/editprofile">Edit Profile</NavLink>
          <NavLink to="/resetpassword">Reset Password</NavLink>
          <NavLink to="/package-details">Package Details</NavLink>
          {/* <NavLink to="/profile_viewers">Profile Viewers</NavLink> */}
          <NavLink to="/profile_viewers">
            Profile Viewers 
            <NotificationCount>{viewerCount > 0 ? viewerCount : 0}</NotificationCount>
          </NavLink>

        </div>
      </NavbarContainer>
    </>
  );
};

export default Navbar;
