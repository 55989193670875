import React, { useEffect, useState } from 'react';
// import HorizontalNavbarAdmin from './HorizontalNavbarAdmin';
// import VerticalNavbarAdmin from './VerticalNavbarAdmin';
import CombinedNavbar from './CombinedNavbar';
import './AdminUserPackageDetails.css';

import './StatsCard.css';
import config from '../functions/config';

 

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  
  const [error, setError] = useState(null); // Initialize error state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // const response = await fetch('http://localhost:8000/api/all-users/');
        const response = await fetch(`${config.apiUrl}all-users/`);
        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    

    fetchUsers();
    
    setLoading(false);
  }, []);

  
  if (loading) return <div>Loading users...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    
    <div className="admin-pages">
      {/* <HorizontalNavbarAdmin />
      <VerticalNavbarAdmin /> */}
      <CombinedNavbar />
      <div className="admin-contents">
      
      <br></br><br></br><br></br><br></br>

        <h2>All Users Package Details</h2><br></br>
        <div className="user-cards">
          {users.map(user => (
            <div className="user-card" key={user.id}>
              {user.userprofile__profile_picture_1 && (
                <img
                  // src={`http://localhost:8000/media/${user.userprofile__profile_picture_1}`}
                  src={`${config.baseUrl}/media/${user.userprofile__profile_picture_1}`} 
                  alt="Profile"
                  className="profile-picture"
                  onError={(e) => { e.target.onerror = null; e.target.src = 'path/to/default/image.png'; }}
                />
              )}
              
              <h3>{user.username}</h3>
              <h4>Package Details</h4>
              <p>Email: {user.email}</p>
              <p>Status: {user.is_approved}</p>
              <p>Name: {user.userprofile__name}</p>
              {/* <p>Gender: {user.userprofile__gender}</p>
              <p>Age: {user.userprofile__age}</p>
              <p>Height: {user.userprofile__height}</p>
              <p>Education: {user.userprofile__education}</p>
              <p>Job: {user.userprofile__job}</p>
              <p>Place: {user.userprofile__place}</p>
              <p>District: {user.userprofile__district}</p>
              <p>Mobile: {user.userprofile__mobile}</p>
              <p>Religion: {user.userprofile__religion}</p>
              <p>Caste: {user.userprofile__caste}</p>
              <p>Marriage Level: {user.userprofile__marriage_level}</p>
              <p>Mother's Name: {user.userprofile__mother_name}</p>
              <p>Mother's Job: {user.userprofile__mother_job}</p>
              <p>Father's Name: {user.userprofile__father_name}</p>
              <p>Father's Job: {user.userprofile__father_job}</p> */}
              <p>Status: {user.userprofile__status}</p>

              
              {user.userprofile__payment__package_id ? (
                <div>
                  <p>Package ID: {user.userprofile__payment__package_id}</p>
                  <p>Start Date: {new Date(user.userprofile__payment__start_date).toLocaleDateString()}</p>
                  <p>End Date: {user.userprofile__payment__end_date ? new Date(user.userprofile__payment__end_date).toLocaleDateString() : 'N/A'}</p>
                  <p>Amount: ${parseFloat(user.userprofile__payment__amount).toFixed(2)}</p>
                </div>
              ) : (
                <p>No payment details available.</p>
              )}

              {/* <h4>Sibling Details</h4>
              {user.userprofile__sibling_1_name || user.userprofile__sibling_2_name || user.userprofile__sibling_3_name ? (
                <>
                  {user.userprofile__sibling_1_name && (
                    <div>
                      <p>Name: {user.userprofile__sibling_1_name}</p>
                      <p>Marital Status: {user.userprofile__sibling_1_married ? 'Married' : 'Not Married'}</p>
                    </div>
                  )}
                  {user.userprofile__sibling_2_name && (
                    <div>
                      <p>Name: {user.userprofile__sibling_2_name}</p>
                      <p>Marital Status: {user.userprofile__sibling_2_married ? 'Married' : 'Not Married'}</p>
                    </div>
                  )}
                  {user.userprofile__sibling_3_name && (
                    <div>
                      <p>Name: {user.userprofile__sibling_3_name}</p>
                      <p>Marital Status: {user.userprofile__sibling_3_married ? 'Married' : 'Not Married'}</p>
                    </div>
                  )}
                </>
              ) : (
                <p>No siblings data available.</p>
              )}

              <div className="profile-pictures">
                <h4>Profile Pictures</h4>
                {[1, 2, 3, 4, 5].map(num => (
                  user[`userprofile__profile_picture_${num}`] && (
                    <img
                      key={num}
                      src={`http://localhost:8000/media/${user[`userprofile__profile_picture_${num}`]}`}
                      alt={`Profile ${num}`}
                      className="profile-picture"
                      onError={(e) => { e.target.onerror = null; e.target.src = 'path/to/default/image.png'; }}
                    />
                  )
                ))}
              </div> */}

              
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
