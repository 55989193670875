import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import config from '../functions/config';

const NavbarContainer = styled.div`
  position: fixed;
  top: 60px; /* Below the topbar */
  left: 0;
  width: 200px;
  height: calc(100% - 60px);
  background-color: #333; /* Dark Grey */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-200px)')};
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow-y: auto;

  @media (min-width: 768px) {
    transform: translateX(0);
  }
`;

const Topbar = styled.div`
  width: 100%;
  height: 60px;
  background-color: #9b2929; /* Dark Red */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  z-index: 1100;
  position: fixed;
  top: 0;
  left: 0;

  h3 {
    color: white;
    margin: 0;
  }
`;

const NavItems = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 20px; /* Adjusted for spacing */
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #f5f5f5; /* Light Grey */
  padding: 15px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #7e7a7a; /* Hover color */
  }

  .notification-badge {
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 5px;
    margin-left: 5px;
  }
`;

const ToggleButton = styled.button`
  background-color: #9b2929; /* Match topbar color */
  border: none;
  color: white;
  padding: 15px;
  cursor: pointer;
  font-size: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);

  @media (min-width: 768px) {
    display: none;
  }
`;

const CombinedNavbar = () => {
  const navigate = useNavigate();
  const [unapprovedCount, setUnapprovedCount] = useState(0);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  useEffect(() => {
    const fetchUnapprovedCount = async () => {
      try {
        const response = await fetch(`${config.apiUrl}unapproved-users/count`);
        const data = await response.json();
        setUnapprovedCount(data.count);
      } catch (error) {
        console.error("Error fetching unapproved users count:", error);
      }
    };

    fetchUnapprovedCount();
  }, []);

  const toggleNavbar = () => {
    setIsNavbarOpen(prevState => !prevState);
  };

  const handleLogout = () => {
    navigate('/'); // Redirect to home page or login page
  };

  const handleLinkClick = () => {
    setIsNavbarOpen(false); // Close the navbar on link click
  };

  return (
    <div>
      {/* Horizontal Navbar */}
      <Topbar>
        <h3>Welcome Admin</h3>
        <button className="logout-button" onClick={handleLogout}>Logout</button>
        <ToggleButton onClick={toggleNavbar}>
          <FontAwesomeIcon icon={isNavbarOpen ? faTimes : faBars} />
        </ToggleButton>
      </Topbar>

      {/* Combined Navbar */}
      <NavbarContainer isOpen={isNavbarOpen}>
        <NavItems>
          <NavLink to="/admin" onClick={handleLinkClick}>
            Admin Dashboard
            {unapprovedCount > 0 && <span className="notification-badge">{unapprovedCount}</span>}
          </NavLink>
          <NavLink to="/userpackagesadmin" onClick={handleLinkClick}>Users Package</NavLink>
          <NavLink to="/unapproved-users" onClick={handleLinkClick}>
            Approve/Disapprove
            {unapprovedCount > 0 && <span className="notification-badge">{unapprovedCount}</span>}
          </NavLink>
          <NavLink to="/approved-users" onClick={handleLinkClick}>Approved Users</NavLink>
        </NavItems>
      </NavbarContainer>
    </div>
  );
};

export default CombinedNavbar;
