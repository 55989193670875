import React, { useEffect, useState } from 'react';
import CombinedNavbar from './CombinedNavbar';
import './AdminPage.css';
import Cookies from 'js-cookie';
import StatsCard from './StatsCard';
import './StatsCard.css';
import config from '../functions/config';
import styled from 'styled-components';

const StyledModal = styled.div`
  display: ${props => (props.$isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 600px; /* Limit modal width */
  width: 90%; /* Responsive width */
  max-height: 80vh; /* Limit modal height */
  overflow-y: auto; /* Enable vertical scrolling */
`;

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [stats, setStats] = useState({ userType2Count: 0, approvedUsersCount: 0, totalPaymentAmount: 0 });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${config.apiUrl}all-users/`);
        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchStats = async () => {
      try {
        const response = await fetch(`${config.apiUrl}dashboard-stats/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch stats");
        }
        const data = await response.json();
        
        setStats({
          userType2Count: data.user_type_2_count || 0,
          approvedUsersCount: data.approved_users_count || 0,
          totalPaymentAmount: typeof data.total_payment_amount === 'number' ? data.total_payment_amount : 0,
        });
      } catch (error) {
        setError(error.message);
      }
    };

    fetchUsers();
    fetchStats();
  }, []);

  const handleDelete = async (userId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this user?");
    
    if (confirmDelete) {
      try {
        const response = await fetch(`${config.apiUrl}delete-user/${userId}/`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken'),
            'Authorization': `Token ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to delete user: ${errorText}`);
        }

        setUsers(users.filter(user => user.id !== userId));
        alert("User deleted successfully.");
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  const openModal = (user) => {
    const endDate = new Date(user.userprofile__payment__end_date);
    setSelectedUser({ ...user, endDate });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  if (loading) return <div>Loading users...</div>;
  if (error) return <div>Error: {error}</div>;

  const currentDate = new Date();

  return (
    <div className="admin-page">
      <CombinedNavbar />
      <div className="admin-content">
        <p>Welcome, Admin!</p>

        <h2>Dashboard Statistics</h2>
        <div className="stats-container" style={{ display: 'flex', flexWrap: 'wrap' }}>
          <StatsCard title="Total Users" value={stats.userType2Count} color="#4CAF50" />
          <StatsCard title="Total Approved Users" value={stats.approvedUsersCount} color="#2196F3" />
          <StatsCard title="Total Amount" value={`$${stats.totalPaymentAmount.toFixed(2)}`} color="#FF9800" />
        </div>

        <h2>All Users</h2>
        <div className="user-cards">
          {users.map(user => {
            const endDate = new Date(user.userprofile__payment__end_date);
            if (user.userprofile__payment__package_id && endDate < currentDate) {
              return null;
            }
            
            return (
              <div className="user-card" key={user.id}>
                {user.userprofile__profile_picture_1 && (
                  <img
                    src={`${config.baseUrl}/media/${user.userprofile__profile_picture_1}`}
                    alt="Profile"
                    className="profile-picture"
                    onError={(e) => { e.target.onerror = null; e.target.src = 'path/to/default/image.png'; }}
                  />
                )}
                <h3>{user.username}</h3>
                <p>Email: {user.email}</p>
                <p>Status: {user.is_approved}</p>
{/* 
                <button onClick={() => openModal(user)}>View Details</button>
                <button onClick={() => handleDelete(user.id)}>Delete User</button> */}
               <div className="button-container">
    <button className="button common-button view-button" onClick={() => openModal(user)}>View Details</button>
    <button className="button common-button delete-button" onClick={() => handleDelete(user.id)}>Delete User</button>
  </div>

              </div>
            );
          })}
        </div>

        <StyledModal $isOpen={isModalOpen}>
          {isModalOpen && selectedUser && (
            <ModalContent>
              <span className="close" onClick={closeModal}>&times;</span>
              <h2>User Details</h2>
              <p><strong>Username:</strong> {selectedUser.username}</p>
              <p><strong>Email:</strong> {selectedUser.email}</p>
              <p><strong>Name:</strong> {selectedUser.userprofile__name}</p>
              <p><strong>Gender:</strong> {selectedUser.userprofile__gender}</p>
              <p><strong>Age:</strong> {selectedUser.userprofile__age}</p>
              <p><strong>Height:</strong> {selectedUser.userprofile__height}</p>
              <p><strong>Education:</strong> {selectedUser.userprofile__education}</p>
              <p><strong>Job:</strong> {selectedUser.userprofile__job}</p>
              <p><strong>Place:</strong> {selectedUser.userprofile__place}</p>
              <p><strong>District:</strong> {selectedUser.userprofile__district}</p>
              <p><strong>Mobile:</strong> {selectedUser.userprofile__mobile}</p>
              <p><strong>Religion:</strong> {selectedUser.userprofile__religion}</p>
              <p><strong>Caste:</strong> {selectedUser.userprofile__caste}</p>
              <p><strong>Marriage Level:</strong> {selectedUser.userprofile__marriage_level}</p>
              <p><strong>Mother's Name:</strong> {selectedUser.userprofile__mother_name}</p>
              <p><strong>Mother's Job:</strong> {selectedUser.userprofile__mother_job}</p>
              <p><strong>Father's Name:</strong> {selectedUser.userprofile__father_name}</p>
              <p><strong>Father's Job:</strong> {selectedUser.userprofile__father_job}</p>
              <p><strong>Status:</strong> {selectedUser.userprofile__status}</p>

              <h4>Payment Details</h4>
              {selectedUser.userprofile__payment__package_id ? (
                <div>
                  <p><strong>Package ID:</strong> {selectedUser.userprofile__payment__package_id}</p>
                  <p><strong>Start Date:</strong> {new Date(selectedUser.userprofile__payment__start_date).toLocaleDateString()}</p>
                  <p><strong>End Date:</strong> {selectedUser.endDate.toLocaleDateString()}</p>
                  <p><strong>Amount:</strong> ${parseFloat(selectedUser.userprofile__payment__amount).toFixed(2)}</p>
                </div>
              ) : (
                <p>No payment details available.</p>
              )}

              <h4>Sibling Details</h4>
              {selectedUser.userprofile__sibling_1_name || selectedUser.userprofile__sibling_2_name || selectedUser.userprofile__sibling_3_name ? (
                <>
                  {selectedUser.userprofile__sibling_1_name && (
                    <div>
                      <p><strong>Name:</strong> {selectedUser.userprofile__sibling_1_name}</p>
                      <p><strong>Marital Status:</strong> {selectedUser.userprofile__sibling_1_married ? 'Married' : 'Not Married'}</p>
                    </div>
                  )}
                  {selectedUser.userprofile__sibling_2_name && (
                    <div>
                      <p><strong>Name:</strong> {selectedUser.userprofile__sibling_2_name}</p>
                      <p><strong>Marital Status:</strong> {selectedUser.userprofile__sibling_2_married ? 'Married' : 'Not Married'}</p>
                    </div>
                  )}
                  {selectedUser.userprofile__sibling_3_name && (
                    <div>
                      <p><strong>Name:</strong> {selectedUser.userprofile__sibling_3_name}</p>
                      <p><strong>Marital Status:</strong> {selectedUser.userprofile__sibling_3_married ? 'Married' : 'Not Married'}</p>
                    </div>
                  )}
                </>
              ) : (
                <p>No siblings data available.</p>
              )}

              <div className="profile-pictures">
                <h4>Profile Pictures</h4>
                {[1, 2, 3, 4, 5].map(num => (
                  selectedUser[`userprofile__profile_picture_${num}`] && (
                    <img
                      key={num}
                      src={`${config.baseUrl}/media/${selectedUser[`userprofile__profile_picture_${num}`]}`}
                      alt={`Profile ${num}`}
                      className="profile-picture"
                      onError={(e) => { e.target.onerror = null; e.target.src = 'path/to/default/image.png'; }}
                    />
                  )
                ))}
              </div>
            </ModalContent>
          )}
        </StyledModal>
      </div>
    </div>
  );
};

export default AdminPage;
