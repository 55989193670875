import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserNavbar from './UserNavbar'; 
import './PackageDetails.css'; 
import config from '../functions/config';

const PackageDetails = () => {
  const [packageDetails, setPackageDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPackageDetails = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/'); 
        return;
      }

      try {
        // const response = await fetch('http://localhost:8000/api/package_details/', {
          const response = await fetch(`${config.apiUrl}package_details/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Error fetching package details:", errorText);
          if (response.status === 404) {
            alert("No payment details found for this user.");
          } else if (response.status === 401) {
            navigate('/'); 
          } else {
            throw new Error(`Failed to fetch package details: ${errorText}`);
          }
        }

        const data = await response.json();
        setPackageDetails(data);
      } catch (error) {
        console.error("Fetch error:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPackageDetails();
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!packageDetails) {
    return <div>No package details found.</div>;
  }

  const isExpired = new Date(packageDetails.end_date) < new Date();

  const handleRenewal = () => {
    if (!packageDetails.userid) {
      console.error("User ID not found in package details.");
      return; // or alert the user
    }
    
    navigate('/paymentrenew', {
      state: {
        userId: packageDetails.userid,
        packageDetails: {
          id: packageDetails.package_id,
          name: packageDetails.name,
          amount: packageDetails.amount,
          duration: packageDetails.duration, // assuming you have this field
          startDate: packageDetails.start_date,
          endDate: packageDetails.end_date,
        }
      }
    });
  };

  return (
    <div>
      <UserNavbar />
      <div className="package-details-container">
        <h2>Package Details</h2>
        <div className="package-details">
          <div className="detail-item">
            <strong>User ID:</strong> {packageDetails.userid}
          </div>
          <div className="detail-item">
            <strong>Name:</strong> {packageDetails.name}
          </div>
          <div className="detail-item">
            <strong>Gender:</strong> {packageDetails.gender}
          </div>
          <div className="detail-item">
            <strong>Age:</strong> {packageDetails.age}
          </div>
          <div className="detail-item">
            <strong>Job:</strong> {packageDetails.job}
          </div>
          <div className="detail-item">
            <strong>Package ID:</strong> {packageDetails.package_id}
          </div>
          <div className="detail-item">
            <strong>Amount:</strong> {packageDetails.amount}
          </div>
          <div className="detail-item">
            <strong>Start Date:</strong> {packageDetails.start_date}
          </div>
          <div className="detail-item">
            <strong>End Date:</strong> {packageDetails.end_date}
          </div>
        </div>

        {isExpired ? (
          <div className="expired-message">
            <strong>This package is expired.</strong>
            <button onClick={handleRenewal} className="renewal-button">
              Renew Package
            </button>
          </div>
        ) : (
          <div className="active-message">
            <strong>Your package is active.</strong>
          </div>
        )}
      </div>
    </div>
  );
};

export default PackageDetails;
