import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 
import './Navbar.css'; 
import LoginForm from './LoginForm';

const Navbar = () => {
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleLoginClick = () => {
        setShowLoginForm(true);
        setIsMenuOpen(false); // Close mobile menu when login is clicked
    };

    const handleCloseLoginForm = () => {
        setShowLoginForm(false);
    };

    // const toggleMenu = () => {
    //     setIsMenuOpen(!isMenuOpen);
    // };

    const handleLinkClick = () => {
        setIsMenuOpen(false); // Close menu when a link is clicked
    };

    return (
        <>
            <nav className="navbar">
                <div className="navbar-brand">
                    <h1>Matrimony</h1>
                </div>
                {/* <button className="navbar-toggle" onClick={toggleMenu}>
                    <span className="navbar-toggle-icon"></span>
                </button> */}
                <div className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
                    <Link to="/" onClick={handleLinkClick}  className="navbar-btn">Home</Link>
                    <Link to="#about" onClick={handleLinkClick}  className="navbar-btn">About Us</Link>
                    {/* <Link to="/contact" onClick={handleLinkClick}>Contact</Link> */}
                    {/* <button className="navbar-btn" onClick={handleLoginClick}>Login</button> */}
                    

                    <Link to="/register" onClick={handleLinkClick} className="navbar-btn">Registration</Link>
                    

                    <Link to="" onClick={handleLoginClick} className="navbar-btn">Login</Link>
                </div>
            </nav>
            {showLoginForm && <LoginForm onClose={handleCloseLoginForm} />}
        </>
    );
};

export default Navbar;
